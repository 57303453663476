<template>
  <div>
    <b-card title="Administracion - Ajuste Negativo">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="
                  $router.push({ name: 'administracion-nuevo-ajuste-negativo' })
                "
              >
                <!-- @click="$refs.modalFormAjusteRef.abrir()" -->
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVO</span>
              </b-button>

              <b-button
                class="d-none d-md-block"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                :disabled="buttonDisabled"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span class="align-middle">VER</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="confirmarEliminar(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ANULAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="4">
                <b-input-group>
                  <flat-pickr
                    v-model="fechaInput"
                    class="form-control form-control-sm-small"
                    placeholder="Filtro por rango de fechas"
                    :config="config"
                    @on-change="onChange"
                    @on-close="onClose"
                  />
                  <b-input-group-append>
                    <b-button
                      @click="(fecha = null), (fechaInput = null)"
                      size="sm"
                      variant="outline-dark"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItemAjuste="selectItemAjuste"
        />
      </b-card-text>

      <b-card-text>
        <b-row>
          <b-col cols="6" md="6">
            <span class="text-nowrap text-bold">
              <strong>
                Mostrando {{ from }} - {{ to }} de {{ total }} Registros
              </strong>
            </span>
          </b-col>
          <b-col cols="6" md="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="handlePageChange"
              align="right"
              size="sm"
              class="my-0"
            >
              <template #prev-text>
                <feather-icon size="18" icon="ChevronLeftIcon" />
              </template>
              <template #next-text>
                <feather-icon size="18" icon="ChevronRightIcon" />
              </template>
              <template #last-text>
                <feather-icon size="18" icon="ChevronsRightIcon" />
              </template>
              <template #first-text>
                <feather-icon size="18" icon="ChevronsLeftIcon" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>
  
  <script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import axiosIns from "@/libs/axios";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    BPagination,
    vSelect,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,

      // filtros
      fecha: null,
      fechaInput: null,
      config: {
        locale: Spanish,
        maxDate: "today",
        mode: "range",
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
      },
    };
  },
  created() {},
  methods: {
    listar() {
      this.loading = true;
      axiosIns
        .get("ajuste-negativo")
        .then((res) => {
          this.items = res.data.rows;
          this.item = [];
          this.buttonDisabled = true;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // Emit modal
    handlePageChange(value) {
      this.currentPage = value;
      this.listar();
    },
    selectItemAjuste(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    confirmarEliminar(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea anular el ajuste", {
          title: "ANULAR AJUSTE ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si, Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.eliminar(item.id);
          }
        });
    },
    eliminar(id) {
      axiosIns
        .delete("ajuste-negativo/" + id)
        .then((res) => {
          this.$bvToast.toast("Se elimino correctamente.", {
            title: "OK!",
            variant: "success",
            solid: false,
          });
          this.listar();
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.error, {
            title: "ADVERTENCIA!",
            variant: "danger",
            solid: false,
          });
        });
    },
    textFormatter(value) {
      return value.toUpperCase();
    },

    // filtros
    onChange(selectedDates, dateStr, instance) {
      if (selectedDates[0] && selectedDates[1]) {
        let dateStart = instance.formatDate(selectedDates[0], "Y-m-d");
        let dateEnd = instance.formatDate(selectedDates[1], "Y-m-d");
        this.fecha = { dateStart, dateEnd };
      }
    },
    onClose(selectedDates, dateStr, instance) {
      if (selectedDates[0] && selectedDates[1]) {
      } else {
        this.fecha = null;
      }
    },
  },
  mounted() {
    this.listar();
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
  